table {
    page-break-inside: auto
}

tr {
    page-break-inside: avoid;
    page-break-after: auto
}

thead {
    display: table-row-group
}

tfoot {
    display: table-footer-group
}

.placeholder {
    background: #b77eff66;
    padding: 0 2px;
    border-radius: 4px;
    margin: 0px 0px 2px 0px;
    opacity: 1;
    cursor: pointer;
    position: relative;
    transition: 0.25s all;
}

.ck-content {
    border: 1px solid #EDEEF0;
    box-shadow: 0px 0px 8px 0px #0000000A;
    max-width: 21cm;
    border-radius: 12px;
    padding: 12px 16px;
    margin: 0;
}

.ck-content *:not(.table *) {
    overflow-x: scroll;
    max-width: calc(min(21cm, 100vw) - 58px) !important;
}

.ck-header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: center;
    margin: 16px auto;
}

.ck-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    background-color: #A165FD;
    box-shadow: 0px 4px 8px 0px #A165FD29;
    padding: 16px;
    border-radius: 8px;
    cursor: pointer;
    color: #fff;
    width: max-content;
}

.ck-content .m-0 {
    margin: 0 !important;
}

.ck-content .m-0 {
    padding: 0 !important;
}

.ck-content .table>figcaption {
    display: table-caption;
    caption-side: top;
    word-break: break-word;
    text-align: center;
    color: var(--ck-color-table-caption-text);
    background-color: var(--ck-color-table-caption-background);
    padding: .6em;
    font-size: .75em;
    outline-offset: -1px;
}

.ck-content .table {
    margin: 4px auto;
    display: block;
    overflow-x: scroll;
}

.ck-content .table table {
    min-width: calc(min(21cm, 100vw) - 58px) !important;
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px double transparent;
}

.ck-content .table table td,
.ck-content .table table th {
    min-width: 2em;
    padding: .4em;
    border: 1px solid transparent;
}

.ck-content .table table th {
    font-weight: bold;
    background: #0000000d;
}

.ck-content[dir="rtl"] .table th {
    text-align: right;
}

.ck-content[dir="ltr"] .table th {
    text-align: left;
}

.ck-content .table .ck-table-resized {
    /* width: 100% !important; */
    table-layout: fixed;
}

.ck-content .table table {
    /* width: 100% !important; */
    table-layout: fixed;
    overflow: hidden;
}

.ck-content .table td,
.ck-content .table th {
    overflow-wrap: break-word;
    position: relative;
}

.ck-content .media {
    clear: both;
    margin: 0.9em 0;
    display: block;
    min-width: 15em;
}

.ck-content .todo-list {
    list-style: none;
}

.ck-content .todo-list li {
    margin-bottom: 5px;
}

.ck-content .todo-list li .todo-list {
    margin-top: 5px;
}

.ck-content .todo-list .todo-list__label>input {
    -webkit-appearance: none;
    display: inline-block;
    position: relative;
    width: var(--ck-todo-list-checkmark-size);
    height: var(--ck-todo-list-checkmark-size);
    vertical-align: middle;
    border: 0;
    left: -25px;
    margin-right: -15px;
    right: 0;
    margin-left: 0;
}

.ck-content .todo-list .todo-list__label>input::before {
    display: block;
    position: absolute;
    box-sizing: border-box;
    content: "";
    width: 100%;
    height: 100%;
    border: 1px solid #333333;
    border-radius: 2px;
    transition: 250ms ease-in-out box-shadow, 250ms ease-in-out background, 250ms ease-in-out border;
}

.ck-content .todo-list .todo-list__label>input::after {
    display: block;
    position: absolute;
    box-sizing: content-box;
    pointer-events: none;
    content: "";
    left: calc(var(--ck-todo-list-checkmark-size) / 3);
    top: calc(var(--ck-todo-list-checkmark-size) / 5.3);
    width: calc(var(--ck-todo-list-checkmark-size) / 5.3);
    height: calc(var(--ck-todo-list-checkmark-size) / 2.6);
    border-style: solid;
    border-color: transparent;
    border-width: 0 calc(var(--ck-todo-list-checkmark-size) / 8) calc(var(--ck-todo-list-checkmark-size) / 8) 0;
    transform: rotate(45deg);
}

.ck-content .todo-list .todo-list__label>input[checked]::before {
    background: #26ab33;
    border-color: #26ab33;
}

.ck-content .todo-list .todo-list__label>input[checked]::after {
    border-color: #ffffff;
}

.ck-content .todo-list .todo-list__label .todo-list__label__description {
    vertical-align: middle;
}

.ck-content .image {
    display: table;
    clear: both;
    text-align: center;
    margin: 0.9em auto;
    min-width: 50px;
}

.ck-content .image img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    min-width: 100%;
}

.ck-content .image-inline {
    display: inline-flex;
    max-width: 100%;
    align-items: flex-start;
}

.ck-content .image-inline picture {
    display: flex;
}

.ck-content .image-inline picture,
.ck-content .image-inline img {
    flex-grow: 1;
    flex-shrink: 1;
    max-width: 100%;
}

.ck-content .image.image_resized {
    max-width: 100%;
    display: block;
    box-sizing: border-box;
}

.ck-content .image.image_resized img {
    width: 100%;
}

.ck-content .image.image_resized>figcaption {
    display: block;
}

.ck-content .image>figcaption {
    display: table-caption;
    caption-side: bottom;
    word-break: break-word;
    color: var(--ck-color-image-caption-text);
    background-color: var(--ck-color-image-caption-background);
    padding: .6em;
    font-size: .75em;
    outline-offset: -1px;
}

.ck-content .marker-yellow {
    background-color: var(--ck-highlight-marker-yellow);
}

.ck-content .marker-green {
    background-color: var(--ck-highlight-marker-green);
}

.ck-content .marker-pink {
    background-color: var(--ck-highlight-marker-pink);
}

.ck-content .marker-blue {
    background-color: var(--ck-highlight-marker-blue);
}

.ck-content .pen-red {
    color: var(--ck-highlight-pen-red);
    background-color: transparent;
}

.ck-content .pen-green {
    color: var(--ck-highlight-pen-green);
    background-color: transparent;
}

.ck-content ol {
    list-style-type: decimal;
}

.ck-content ol ol {
    list-style-type: lower-latin;
}

.ck-content ol ol ol {
    list-style-type: lower-roman;
}

.ck-content ol ol ol ol {
    list-style-type: upper-latin;
}

.ck-content ol ol ol ol ol {
    list-style-type: upper-roman;
}

.ck-content ul {
    list-style-type: disc;
}

.ck-content ul ul {
    list-style-type: circle;
}

.ck-content ul ul ul {
    list-style-type: square;
}

.ck-content ul ul ul ul {
    list-style-type: square;
}

.ck-content .image-style-block-align-left,
.ck-content .image-style-block-align-right {
    max-width: calc(100% - var(--ck-image-style-spacing));
}

.ck-content .image-style-align-left,
.ck-content .image-style-align-right {
    clear: none;
}

.ck-content .image-style-side {
    float: right;
    margin-left: var(--ck-image-style-spacing);
    max-width: 50%;
}

.ck-content .image-style-align-left {
    float: left;
    margin-right: var(--ck-image-style-spacing);
}

.ck-content .image-style-align-center {
    margin-left: auto;
    margin-right: auto;
}

.ck-content .image-style-align-right {
    float: right;
    margin-left: var(--ck-image-style-spacing);
}

.ck-content .image-style-block-align-right {
    margin-right: 0;
    margin-left: auto;
}

.ck-content .image-style-block-align-left {
    margin-left: 0;
    margin-right: auto;
}

.ck-content p+.image-style-align-left,
.ck-content p+.image-style-align-right,
.ck-content p+.image-style-side {
    margin-top: 0;
}

.ck-content .image-inline.image-style-align-left,
.ck-content .image-inline.image-style-align-right {
    margin-top: var(--ck-inline-image-style-spacing);
    margin-bottom: var(--ck-inline-image-style-spacing);
}

.ck-content .image-inline.image-style-align-left {
    margin-right: var(--ck-inline-image-style-spacing);
}

.ck-content .image-inline.image-style-align-right {
    margin-left: var(--ck-inline-image-style-spacing);
}

.ck-content code {
    background-color: #c7c7c74d;
    padding: .15em;
    border-radius: 2px;
}

.ck-content blockquote {
    overflow: hidden;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin-left: 0;
    margin-right: 0;
    font-style: italic;
    border-left: solid 5px #cccccc;
}

.ck-content[dir="rtl"] blockquote {
    border-left: 0;
    border-right: solid 5px #cccccc;
}

.ck-content .text-tiny {
    font-size: .7em;
}

.ck-content .text-small {
    font-size: .85em;
}

.ck-content .text-big {
    font-size: 1.4em;
}

.ck-content .text-huge {
    font-size: 1.8em;
}

.ck-content .mention {
    background: var(--ck-color-mention-background);
    color: var(--ck-color-mention-text);
}

.ck-content hr {
    margin: 15px 0;
    height: 4px;
    background: #dedede;
    border: 0;
}

.ck-content pre {
    padding: 1em;
    color: #353535;
    background: #c7c7c74d;
    border: 1px solid #c4c4c4;
    border-radius: 2px;
    text-align: left;
    direction: ltr;
    tab-size: 4;
    white-space: pre-wrap;
    font-style: normal;
    min-width: 200px;
}

.ck-content pre code {
    background: unset;
    padding: 0;
    border-radius: 0;
}

@media (min-width: 1024px) {
    .ck-content {
        padding: 24px 32px;
    }

    .ck-content *:not(.table *) {
        overflow-x: scroll;
        max-width: calc(min(21cm, 100vw) - 114px) !important;
    }

    .ck-content .table table {
        min-width: calc(min(21cm, 100vw) - 114px) !important;
    }
}